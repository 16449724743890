<template>
  <div>
    <div class="top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }" @click.native="change">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path:'/news'}">新闻报道</el-breadcrumb-item>
        <el-breadcrumb-item>2019创富中国“智能+”专场活动在成都成功举办</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="left">
        <h2>2019创富中国“智能+”专场活动在成都成功举办</h2>
        <div class="second">
          <div class="time-img"><img src="@/assets/img/time.jpg" alt=""></div>
          <span class="time">2019/5/30</span>
          <span class="time-title">创富中国年度总比赛</span>
          <span class="gold">冠军
            <img src="@/assets/img/media.png" alt="">
          </span>
        </div>
        <p class="third-p1">2019年5月30日下午，2019创富中国“智能+”专场活动在成都高新区成功举办。本场活动以“智能创享生活”为主题，围绕智慧城市、大数据、物联网、人工智能、智能制造、机器人等热门话题和最新产学研究成果展开深入交流和成果展演。</p>
        <p class="fourth-p3">本场活动由成都高新区管委会、成都市科学技术局、成都产业集团主办；成都创新风险投资有限公司、成都软交所信息服务有限公司承办；成都高新区科技和人才工作局、成都生产力促进中心、成都高新锦泓科技小额贷款有限责任公司共同协办。</p>
        <p class="fourth-p3">作为创业天府菁蓉创享会的系列活动之一，活动现场聚集了相关行业专家、创业机构、企业家、创业者和海内外政商学嘉宾等300余人，并邀请到了7个创业团队进行了项目路演。</p>
        <div class="firth">
          <div><img src="@/assets/img/8.jpg" alt=""></div>
        </div>
        <p class="fourth-p2 fourth-center">成都市新经济委副主任李佳林致辞</p>
        <div class="firth">
          <div><img src="@/assets/img/chuangfu1.jpg" alt=""></div>
        </div>
        <p class="fourth-p2 fourth-center">成都产业集团副总经理黄少翔致辞</p>
        <p class="fourth-p3">成都市新经济委副主任李佳林、成都产业集团副总经理黄少翔、成都生产力促进中心副主任张弛、加拿大驻成都贸易代表处商务官Ricky Qian 、成都物联网产业发展联盟秘书长李俊华一同见证活动启幕。</p>
        <div class="firth">
          <div><img src="@/assets/img/chuangfu2.jpg" alt=""></div>
        </div>
        <p class="fourth-p2 fourth-center">启幕仪式</p>
        <p class="fourth-p3">本次活动，还邀请到了成都生产力促进中心副主任、成都科技企业孵化器协会理事长张弛现场为大家解读成都市科技企业扶持政策。成都理工大学副教授，硕士生导师周冬梅也到场为大家带来精彩分享。</p>
        <div class="firth">
          <div><img src="@/assets/img/chuangfu3.jpg" alt="" style="margin-bottom:20px"></div>
        </div>
        <div class="firth">
          <div><img src="@/assets/img/chuangfu4.jpg" alt=""></div>
        </div>
        <p class="fourth-p2">青羊总部经济基地、紫荆谷（成都）众创空间、成都商友众创空间、成都芯空间、成都优贝空间被评为2019创富中国优秀合作伙伴。成都创新风险投资有限公司董事长、总经理向烈为获奖企业颁发了奖杯。</p>
        <div class="firth">
          <div><img src="@/assets/img/chuangfu5.jpg" alt=""></div>
        </div>
        <p class="fourth-p2">项目路演环节，城市名片、德能森智能化物联网综合服务平台、低速无人车项目、观智创新-智云助手、全自动AI金融大数据分析、商用空间拓展机器人、智能寄存柜七个项目先后上台路演展示，最终，全自动AI金融大数据分析、德能森智能化物联网综合服务平台、低速无人车项目三个项目成功晋级，获得2019创富中国总决赛入场资格。</p>
        <div class="firth">
          <div><img src="@/assets/img/chuangfu6.jpg" alt=""></div>
        </div>
        <p class="fourth-p2">经由评审团提问打分，全自动AI金融大数据分析、城市大脑、来画视频三个项目分获创富中国年度总决赛冠、亚、季军，最具商业价值奖花落“汇学帮”人工智能产业人才助学平台。</p>
        <p style="text-align:right;margin-top:2rem;margin-bottom:0rem">原文链接：<a href="https://mp.weixin.qq.com/s/qMeo30a-fNpGjYIUT6QFaw">https://mp.weixin.qq.com/s/qMeo30a-fNpGjYIUT6QFaw</a></p>
      </div>

      <div class="right">
        <h3>相关报道</h3>
        <ul>
          <li>
            <a href="newone"><img src="@/assets/img/865.jpg" alt=""></a>
            <div class="middle">
              <a href="newone" class="h4">海内外优质创新项目齐聚汕头首届创业之星大赛大奖出炉</a>
              <span class="middle-p1">汕头.创业之星比赛</span>
              <span class="middle-p2">三等奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/1/15 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="neweight"><img src="@/assets/img/867.jpg" alt=""></a>
            <div class="middle">
              <a href="neweight" class="h4">中国（小谷围）人工智能创新创业大赛圆满落下帷幕</a>
              <span class="middle-p1">人工智能创新创业比赛</span>
              <span class="middle-p2">优胜奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/1/10 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="newseven"><img src="@/assets/img/869.jpg" alt=""></a>
            <div class="middle">
              <a href="newseven" class="h4">创新创业大赛|决赛圆满告捷，冠军花落谁家</a>
              <span class="middle-p1">青年人才创新创业大赛</span>
              <span class="middle-p2">第三名
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/3/29 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      currentRouter: state => state.nav.currentRouter
    })
  },
  methods: {
    ...mapActions({
      changeNavActive: 'nav/changeNavActive'
    }),
    change() {
      this.changeNavActive('首页')
      console.log(1)
    }
  }

}
</script>

<style lang="scss" scoped>
.fourth-center{
  text-align: center;
}
p{
  text-align: left;
  text-indent:2em;
}
img{
     display: block;
}
.top{
  margin-top: 65px;
  // padding: 0 205px;
  padding-left: 5%;
  width: 100%;
  height: 70px;
  background-color: #f2f2f2;
}
.el-breadcrumb {
    line-height: 70px;
}

.main{
    margin:auto;
    padding: 30px;
    width: 1420px;
    overflow: hidden;
}
.left{
    width: 65%;
    padding: 30px;
    float: left;
    overflow: hidden;
    background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(4, 0, 0, 0.15);
    border-radius: 20px;
    .second{
        overflow: hidden;
        margin-top: 40px;
    }
    .time-img{
        width: 20px;
        height: 20px;
        float: left;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .time{
        float: left;
        margin-left:10px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        color: #999999;
    }
    .time-title{
        background-color: #eff3f5;
        border-radius: 4px;
        float: left;
        font-size: 14px;
        padding: 8px 11px 8px 9px;
        line-height: 10px;
        letter-spacing: 1px;
        color: #999999;
        margin-left: 40px;
        margin-right: 20px;
    }
    .gold{
    background-color: #ffd5d5;
    float: left;
    border-radius: 4px;
	font-size: 14px;
    padding: 8px 11px 8px 9px;
    line-height: 10px;
	letter-spacing: 1px;
    color: #ff3333;
    position: relative;
    img{
        position: absolute;
        top: 0;
        left: -14px;
    }
    }
    .third-p1{
    margin-top: 60px;
    font-size: 16px;
	line-height: 30px;
	letter-spacing: 0px;
	color: #666666;
    }
    .fourth{
        padding: 0 14px;
        div img{
            width: 375px;
            height: 251px;
        }
        .fourth-img1{
            margin-right: 14px;
            float: left;
        }
    }
       .fourth-p2{
           margin-top: 16px;
            font-family: PingFang-SC-Bold;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .fourth-p3{
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .firth{
            padding: 0 14px;
            div img{
                width: 100%;
                height: 511px;
            }
        }
}
ul{
  padding: 0
}
.right{
    width: 21.5%;
    margin-left: 25px;
    float: left;
    overflow: hidden;
    h3{
    font-size: 26px;
	line-height: 48px;
	letter-spacing: 0px;
    color: #000000;
    text-align: left;
    margin-left: 30px;
    position: relative;
    }
    h3::before{
        display: block;
        content: '';
        width: 6px;
        height: 33px;
        background-color: #ff3333;
        position: absolute;
        top: 8px;
        left: -20px;
    }
    ul li{
        width: 100%;
        padding: 10px;
        background-color: #eff3f5;
        border-radius: 8px;
        margin: 15px 0;
        overflow: hidden;
        img{
            width: 100%;
            height: 230px;
            border-radius: 8px;
        }
        .middle{
          width: 100%;
          margin-left: 8px;
          overflow: hidden;
          margin-bottom: 25px;
          span{
              float: left;
              padding: 0 9px;
          }
          .middle-p1{
              background-color: #ffffff;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #999999;
          }
          .middle-p2{
              background-color: #ffd5d5;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #ff3333;
              margin-left: 25px;
              position: relative;
              img{
                  width: 20px;
                  height: 26px;
                  position: absolute;
                  top: 1px;
                  left: -15px;
              }
          }
        }
        .h4{
          display: block;
            width: 90%;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #333333;
            text-align: left;
            margin: 16px 0;
        }
        .time{
            overflow: hidden;
            margin-right: 8px;
            img{
                width: 20px;
                height: 20px;
                float: right;
            }
            .time-word{
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 1px;
                color: #999999;
                float: right;
                margin-left: 15px;
                margin-right: 5px;
            }
        }
    }
}
a:hover{
      text-decoration:none;
      color: #e45151  !important;
      transition: all 0.3s;
}
</style>
